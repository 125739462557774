import firebase from "firebase";
import Vue from "vue";
export default {
	firestorePath: "users/{userId}",
	firestoreRefType: "doc",
	moduleName: "auth",
	statePropName: "data",
	namespaced: true,
	state: {
		logged_in: false,
		logged_out: false,
		data: {
			status: "published",
			role: "user",
			registration_method: "user",
		},
	},
	serverChange: {
		modifiedHook(updateStore, doc, _, { dispatch }) {
			var organisation = doc.organisation;
			dispatch(
				"organisation/openDBChannel",
				{ id: organisation },
				{ root: true }
			);
			return updateStore(doc);
		},
	},
	getters: {
		id(state) {
			return state.data.id;
		},
		organisation(state){
			return state.data.organisation;
		},
		isContractManager(state) {
			return (
				state.data.role == "superadmin" ||
				state.data.role == "admin" ||
				state.data.role == "contract_manager"
			);
		},
	},
	mutations: {
		SET_LOGGED_IN(state) {
			Vue.set(state, "logged_in", new Date());
		},
		SET_LOGGED_OUT(state) {
			Vue.set(state, "logged_in", false);
			Vue.set(state, "logged_out", new Date());
		},
	},
	actions: {
		start({ dispatch, commit, getters }) {
			return dispatch("openDBChannel").then(() => {
				if (getters.isContractManager) {
					if( getters.organisation ){
						dispatch("organisation/openDBChannel", {id: getters.organisation }, {root: true})
					}
					commit("SET_LOGGED_IN");
					return true;
				} else {
					dispatch("stop");
					throw new Error("not-client");
				}
			});
		},
		stop({ commit, dispatch }) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					commit("SET_LOGGED_OUT");
					dispatch("clearAll", false, { root: true });
					commit("RESET_VUEX_EASY_FIRESTORE_STATE");
					return true;
				});
		},
		addUserDetails({ dispatch }) {
			var user = {
				name: firebase.auth().currentUser.displayName,
				email: firebase.auth().currentUser.email,
			};
			dispatch("patch", user);
		},
	},
};

<template>
	<v-app-bar app flat color="white">
		<v-toolbar-title class="font-weight-light d-none d-md-block">
			<h1 class="mt-0">
				<span style="font-size: 20px">{{ siteTitle }}</span>
				<span v-if="organisation.logo">
					<img height="30" class="ml-2" :src="organisation.logo" />
				</span>
			</h1>
		</v-toolbar-title>
		<v-spacer></v-spacer>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script> 
export default {
	name: "TopNav",
	data: () => {
		return {
			dialog: false,
		};
	},
	inject: ["isMobile"],
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
		height() {
			return this.isMobile ? 50 : 80;
		},
		user() {
			return this.$store.state.auth.data;
		},
		loggedIn() {
			return this.user.id ? true : false;
		},
		siteTitle() {
			return this.$store.state.site_name;
		},
	},

	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
};
</script>

export default {
	firestorePath: "projects",
	firestoreRefType: "collection",
	moduleName: "projects",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-archive-edit",
		name: {
			single: "Project",
			plural: "Projects",
		},
		path: "/projects",
	},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	getters: {
		name(state) {
			return (id) => {
				let project = state.data[id];
				return project ? project.client_name : "";
			};
		},
	},
	mutations: {},
	actions: {
		fetchAll({ dispatch, rootState }) {
			let user = rootState.auth.data;
			if (!user || !user.organisation) {
				return;
			}
			return dispatch("fetchAndAdd", {
				clauses: {
					limit: 1000,
					where: [
						["organisation", "==", user.organisation],
						["display_to_client", "==", true],
					],
				},
			})
		},
	},
};

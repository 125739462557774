<template>
    <v-sheet>
			<render-content id="contractmanagerwelcome"></render-content>
    </v-sheet>
</template>

<script type="text/javascript">
import RenderContent from "@c/ui/RenderContent";
export default {
	name: "Home",
	components: {
		RenderContent,
    }
}
</script>
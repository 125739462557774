
export default {
	firestorePath: "appointments",
	firestoreRefType: "collection",
	moduleName: "appointments",
	statePropName: "data",
	namespaced: true,
	state: {
		last: null,
	},
	sync: {},
	serverChange: {
	},
	getters: {},
	mutations: {},
	actions: {
	},
	model: {},
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import firebase from "firebase";

import mwutils from "@/assets/utilities.js";
import IdleVue from "idle-vue";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.prototype.mwutils = mwutils;



var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
	measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENTID,
};
	

firebase.initializeApp(firebaseConfig);

const appCheck = firebase.appCheck();
appCheck.activate(
	process.env.VUE_APP_FIREBASE_SITE_KEY,
  true);
  

Vue.use(VueGtag, {
	config: { id: firebaseConfig.measurementId }
  }, router)
  
firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store.dispatch("auth/start").catch(() => {
			router.replace("/error");
		});
	}
});

Vue.use(IdleVue, {
	store,
	idleTime: 1200000,
});

// var db = firebase.firestore();
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");

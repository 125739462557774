export default {
    site_name: "MWX Research Platform", 
    roles: "contract_managers",
    role: "contract_manager",
    email: "research@m.works",
    address: "18 St. Cross Street. London, EC1N 8UN",
    colors: ["primary", "secondary", "darkblue",  "lightblue", "pink", "yellow", "green" ],
    status_colors: {
        confirmed: "primary", 
        cancelled: "error", 
        complete: "primary",
        preferred: "primary",
        available: "grey",
        unavailable: "grey",
        booked: "green",
    },
}
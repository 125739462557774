export default {
	getByKey(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] == value);
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyDoesNotContain(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => !i[key] || !i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyContains(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] && i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyIn(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => value.includes(i[key]));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
};

<template>
	<span>
		<nav-item
			:expanded="expanded
			
			"
			@click="dialog = true"
			icon="mdi-logout-variant"
			text="Log out"
		></nav-item>
		<mw-dialog
			v-model="dialog"
			small
			submitBtn
			cancelBtn
			@submit="logout"
			title="Are You Sure?"
		>
			<template v-slot:dialog-body>
				Are you sure you want to log out?
			</template>
		</mw-dialog>
	</span>
</template>

<script type="text/javascript">
import MwDialog from "@c/ui/MwDialog";
import NavItem from "@c/navigation/Item.vue";
export default {
	name: "Logout",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		NavItem,
		MwDialog,
	},
	methods: {
		logout() {
			const self = this;
			self.$store.dispatch("auth/stop").then(() => {
				if (self.$route.path !== "/") {
					self.$router.replace("/");
				}
			});
		},
	},
};
</script>
"

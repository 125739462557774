export default {
	state: {},
	getters: {
		all(_, __, rootState) {
			let appointmentParticipants = Object.values(
				rootState.appointmentParticipants.data
			);
			let rows = appointmentParticipants.map((p) => {
				let row = { ...p };
				let appointment = rootState.appointments.data[row.appointment];
				if (!appointment) {
					return;
				}
				if (row.status == "deleted" || appointment.status == "deleted") {
					return;
				}
				[
					"project",
					"start",
					"quota",
					"methodology",
					"participation_link",
				].forEach((key) => {
					row[`appointment_${key}`] = appointment[key];
				});
				return row;
			});
			return rows.filter((r) => r);
		},
		getByFilters(_, getters) {
			return ({
				projects = [],
				start,
                end,
				appointment_status
			}) => {
            let all = getters.all;
                all = all.filter((f) =>
                    projects.includes( f.appointment_project )
                );
			
				if (start) {
					all = all.filter((f) => {
						return f.appointment_start > start;
					});
				}
				if (end) {
					all = all.filter((f) => {
						return f.appointment_start < end;
					});
				}
				if (appointment_status) {
					all = all.filter((f) =>
						appointment_status == f.appointment_status
					);
				}
				return all;
			};
		},
	},
	actions: {
		fetchAppointmentsForParticipants({ dispatch }, ids) {
			dispatch("appointments/fetchByIds", ids, { root: true });
		},
		fetchParticipantsForAppointments({ dispatch }, ids) {
			return Promise.all( 
                ids.map((id) => {
                    dispatch(
                        "appointmentParticipants/fetchByKey",
                        { key: "appointment", value: id },
                        { root: true }
                    );
                })
            );
		},
		fetchByFilter({ dispatch }, {projects = [],  start, end }) {
            let where = [];
            where.push( ["project", "in", projects ])
            if( start ){
                where.push( ["start", ">", start ])
            }
            if( end ){
                where.push( ["start", "<", end ])
            }
            console.log( where )
			return dispatch(
				"appointments/fetchAndAdd",
				{ clauses: { where } },
				{ root: true }
			).then((res) => {
				if (!res.empty) {
					let ids = res.docs.map((d) => d.id);
					return dispatch("fetchParticipantsForAppointments", ids);
				}
			});
		},
	},
	namespaced: true,
};

export default {
	firestorePath: "incentiveSubmissions",
	firestoreRefType: "collection",
	moduleName: "incentiveSubmissions",
	statePropName: "data",
	namespaced: true,
	state: {},
	serverChange: {},	
	getters: {
		getByFilters(state) {
			return ({
				projects = [],
				date_start,
				date_end,
				appointment_status
			}) => {
				let records = Object.values(state.data);
				if (projects.length) {
					records = records.filter((f) => projects.includes(f.project));
				}
				if (appointment_status) {
					records = records.filter((f) => appointment_status == f.status);
				}
				if (date_start) {
					records = records.filter((f) => { 
						return f.created_at >  date_start;
					} );
				}
				if (date_end) {
					records = records.filter((f) => { 
						return f.created_at < date_end;
					} );
				}
				return records;
			};
		},
	},

	mutations: {},
	actions: {
		fetchByProjects({ dispatch }, projects) {
			dispatch("fetchAndAdd", {
				clauses: { where: [["project", "in", projects]], limit: -1 },
			});
		},
	},
	model: {},
};
